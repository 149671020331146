import React, { useEffect, useState } from 'react';
import Routes from './routes';
import './App.css';
import "bootstrap/dist/css/bootstrap.min.css"
import { apiCall, setDefaultHeader } from "./utils/httpClient";
import axios from 'axios'
import igv from "https://cdn.jsdelivr.net/npm/igv@2.13.5/dist/igv.esm.min.js"

export default function App() {
  return (
    <div>
        <Routes />
    </div>
  );
}