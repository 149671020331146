import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import igv from "https://cdn.jsdelivr.net/npm/igv@2.13.5/dist/igv.esm.min.js"

export default function Blank() {
    let uploadSingleFile = e => {
        const files = e?.target?.files
        var len = files.length;
        var bamFile = null
        var baiFile = null
        for (var i = 0; i < len; i++) {
            var file = files[i];
            var type = file?.name?.split('.').pop()
            if (type == 'bam') {
                bamFile = files[i]
            } else if (type == 'bai') {
                baiFile = files[i]
            }
        }
        if (bamFile && baiFile) {
            openIgv(bamFile, baiFile)
        }
    };

    const openIgv = (url, indexurl) => {
        var igvDiv = document.getElementById("igv-div");
        var options =
        {
            genome: "hg19",
            locus: "chr1:9780269-9780269",
            tracks: [
                {
                    "name": "HG00103",
                    "url": url,
                    "indexURL": indexurl,
                    "format": "bam"
                }
            ]
        };
        igv.createBrowser(igvDiv, options)
            .then(function (browser) {
            })
    }
    const isPreview = false;

    return (
        <div style={{ 'textAlign': 'center', margin: 10 }}>
            <div className="form-upload-container float-right common-btn">
                <div className="input-field-container " style={{ width: 210, justifyContent: 'center', alignItems: 'center' }}>
                    {!isPreview && <input multiple accept='.bam,.bai' type="file" onChange={uploadSingleFile} style={{ width: 200 }} />}
                    {!isPreview && (
                        <div className="customized-fileupload">
                            <span className="header-title">Upload Bam & Bai files</span>
                        </div>
                    )}
                </div>
            </div>
            {/* <h1>Welcome</h1> */}
            <div id="igv-div" >

            </div>
        </div>
    );
}