import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';

import { GLOBAL_URL } from './constants'

const httpClient = axios.create({
    baseURL: `${GLOBAL_URL}/`,
});

export function setDefaultHeader(header, value) {
    httpClient.defaults.headers.common[header] = value
}

export async function apiCall(method, url, data, header = { 'Content-Type': 'application/json' }) {
    try {
        const response = await httpClient({
            method,
            url,
            data,
            headers: header,
            withCredentials: false
        })
        // console.log(`${url}: `, response);
        if (response.status === 200) {
            return response;
        }
        if (response.status === 201) {
            return response;
        }
    } catch (error) {
        console.log('error: ', error);
        if (error.response) {
            if (error.response.status === 401) {
                toast.error(error.response, {
                    position: toast.POSITION.TOP_RIGHT
                });
                return error.response;
            } else if (error.response.status === 500) {
                toast.error(error?.response?.data?.title, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            // console.log('Error data : ', error.response.data);
            // console.log('Error status : ', error.response.status);
            // console.log('Error headers : ', error.response.headers);
        } else if (error.request) {
            toast.error(error.request, {
                position: toast.POSITION.TOP_RIGHT
            });
            // console.log('Error request : ', error.request);
        } else {
            // console.log('Error message : ', error.message);
        }
        // console.log("Error config", error.config);
        // console.log("errorresponse", error.response);
        console.log("Error", error);
        return false
    }
}
