import React, { useEffect, useState } from 'react';
import igv from "https://cdn.jsdelivr.net/npm/igv@2.13.5/dist/igv.esm.min.js"
import { AuthContext } from "../../../Context/context";
import { apiCall, setDefaultHeader } from "../../../utils/httpClient";
import { GLOBAL_URL } from '../../../utils/constants'
import axios from 'axios'
import { useParams } from 'react-router-dom';

function App() {
    const params = useParams();
    console.log(params);
    useEffect(() => {
        handleSubmit()
    }, [])

    async function handleSubmit(event) {
        const params = {
            email: 'dinesh@mailinator.com',
            password: 123,
        }
        axios.post(`${GLOBAL_URL}/user/login`, params, {
        })
            .then(function (response) {
                if (response.data.success) {
                    reportVariantId("Bearer" + ' ' + response.data.payLoad.authToken)
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    const reportVariantId = async (value) => {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': value
        }
        axios.get(`${GLOBAL_URL}/report/${params.variantId}/get_variant_igv`, { headers }).then(function (response) {
            if (response.data.success) {
                openIgv(response.data?.payLoad?.chromosome, response.data?.payLoad?.bamFileUrl, response.data?.payLoad?.baiFileUrl)
            }
        })
            .catch(function (error) {
                console.log(error);
            });
    }

    const openIgv = (locus, url, indexurl) => {
        var igvDiv = document.getElementById("igv-div");
        var options =
        {
            genome: "hg19",
            locus: locus,
            tracks: [
                {
                    "name": "HG00103",
                    "url": url,
                    "indexURL": indexurl,
                    "format": "bam"
                }
            ]
        };
        igv.createBrowser(igvDiv, options)
            .then(function (browser) {
            })
    }

    return (
        <div className="App">
            <div id="igv-div" >

            </div>
        </div>
    );
}

export default App;
