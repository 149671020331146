import React, { useState } from "react";
import { apiCall ,setDefaultHeader} from "../../../utils/httpClient";
import { AuthContext } from "../../../Context/context";


export default function Login() {
  const { signInFun, signOutFun } = React.useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  async function handleSubmit(event) {
    if (email && password) {
      const params = {
        // email: 'dinesh@mailinator.com',
        // password: 123,
        email: email,
        password: password
      }
      const { data } = await apiCall('POST', '/user/login', params)
      console.log('data: 1111', data);
      if (data.success) {
        var userLoginRes = [
          {
            userToken: "01",
            username: data.payLoad.authToken,
          },
        ];
        console.log('data?.payLoad: ', data?.payLoad);
        await signInFun(userLoginRes);
        await localStorage.setItem("userData", data?.payLoad.authToken);
        await setDefaultHeader("Authorization", "Bearer" + data?.payLoad.authToken);
      } else {
        alert(data.message)
      }
    } else {
      alert('please enter email and password')
    }
  }

  return (
    <div className="Auth-form-container">
      <div className="Auth-form">
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">Sign In</h3>
          <div className="form-group mt-3">
            <label>Email address</label>
            <input
              type="email"
              className="form-control mt-1"
              placeholder="Enter email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="form-group mt-3">
            <label>Password</label>
            <input
              type="password"
              className="form-control mt-1"
              placeholder="Enter password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="d-grid gap-2 mt-3">
            <button className="btn btn-primary" onClick={() => handleSubmit()}>
              Submit
            </button>
          </div>
          {/* <p className="forgot-password text-right mt-2">
            Forgot <a href="#">password?</a>
          </p> */}
        </div>
      </div>
    </div>

  );

}