// web
import React, { useEffect, useContext } from "react";
import ApiEndPoint from './utils/apiEndPoints';
import { setDefaultHeader, apiCall } from './utils/httpClient';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import { AuthContext } from './Context/context';
import Login from "./screen/Auth/Login";
import Home from "./screen/Pages/Home";
import Blank from "./screen/Pages/blank";
import Hotspot from "./screen/Pages/Hotspot";

function RoutesFile() {
  let isLogged = false;
  // const Alert = useAlert()
  // const navigate = useNavigate();
  const initialLoginState = {
    isLoading: true,
    userName: null,
    userToken: null,
  };
  const loginReducer = (prevState, action) => {
    switch (action.type) {
      case 'RETRIEVE_TOKEN':
        return {
          ...prevState,
          userToken: action.token,
          isLoading: false,
        };
      case 'LOGIN':
        return {
          ...prevState,
          userName: action.id,
          userToken: action.token,
          isLoading: false,
        };
      case 'LOGOUT':
        return {
          ...prevState,
          userName: null,
          userToken: null,
          isLoading: false,
        };
      case 'REGISTER':
        return {
          ...prevState,
          userName: action.id,
          userToken: action.token,
          isLoading: false,
        };
    }
  };
  const [loginState, dispatch] = React.useReducer(loginReducer, initialLoginState);
  const authContext = React.useMemo(() => ({
    signInFun: async (foundUser) => {
      const userToken = String(foundUser[0].userToken);
      const userName = foundUser[0].username;
      try {
        // await localStorage.setItem('userToken', userToken);
        // window.location.assign('/')
        // navigate('/')
      } catch (e) {
        console.log(e);
      }
      // console.log('user token: ', userToken);
      dispatch({ type: 'LOGIN', id: userName, token: userToken });
    },
    signOutFun: async (user_id) => {
      try {
        await localStorage.removeItem('userData');
        await localStorage.removeItem('userToken');
        window.location.assign('/')
      } catch (e) {
        console.log(e);
      }
      dispatch({ type: 'LOGOUT' });
    },
  }), []);


  useEffect(() => {
    // getToken()
    setTimeout(async () => {
      let userToken;
      userToken = null;
      try {
        userToken = await localStorage.getItem('userToken');
      } catch (e) {
        console.log(e);
      }
      dispatch({ type: 'RETRIEVE_TOKEN', token: userToken });
    }, 1000);

  }, []);

  async function getToken() {
    try {
      const Auth = await localStorage.getItem('userToken');
      const userData = await localStorage.getItem('userData');
      if (Auth == '01') {
        await setDefaultHeader('Authorization', 'Bearer' + ' ' + userData)
      }
    } catch (error) {
      console.log(error)
    }
  }

  if (loginState.isLoading) {

    return (
      <div style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        {/* <Loder /> */}
      </div>

    );

  }

  return (
    <div>
      <AuthContext.Provider value={authContext}>
        <Router>
          <div>
            <Routes>
              {/* <Route path="/home" element={<Home/>} /> */}
              {/* <Route  path="/" element={<Blank />} />  */}
              <Route path="/home/:variantId" element={<Home />} />
              <Route path="/hotspotIGV/:variantId" element={<Hotspot />} />

            </Routes>
          </div>
        </Router>
        {/* {loginState.userToken == null ? (
            <Router>
              <div>
                <Routes>
                  <Route path="/" element={<Home />} />
                </Routes>
              </div>
            </Router>
          )
            :
            <Router>
              <div>
                <Routes>
                  <Route path="/" element={<Login />} />
                </Routes>
              </div>
            </Router>
          } */}
      </AuthContext.Provider>
    </div>
  );
}
export default RoutesFile;